export const LOGIN = "/login";
export const REGISTER = "/register";
export const SEND_PASSWORD_RESET_EMAIL = "/send_password_reset_email";
export const GET_SESSION_INITIALIZE = "/initialize";
export const CHAT_BOX_AI = "/chat";
export const GET_USER_DATA = "/get_user_data";
export const POST_CREATE_CHECKOUT_SESSION = "/create_checkout_session";
export const UPDATE_ACCOUNT_INFO = "/update_account_info";
export const UPDATE_ACCOUNT_UPDATE_PASSWORD = "/change_password";
export const GET_SUPPORTED_INTEGRATIONS = "/get_supported_integrations";
export const GET_CALRY_LINK = "/get_calry_link?";
export const GET_AVAIL_INTEGRATIONS = "/get_avail_integrations";
export const REMOVE_INTEGRATION = "/remove_integration";
export const POST_PROPERTIES = "/properties";
export const GET_QUESTIONNAIRE = "/properties";
export const PULL_CONVERSATION_DATA = "/properties";
export const UPDATE_QUESTIONNAIRE = "/properties";
export const GO_TO_BILLING_PORTAL = "/go_to_billing_portal";
export const LIST_INTEGRATION_PROPERTIES = "/list_integration_properties";
export const DELETE_INTEGRATION_PROPERTIES = "/properties";
export const SUPPORTING_DOCUMENT = "/properties";
export const SUPPORTING_URL = "/properties";
export const TOGGLE_CHATBOT = "/toggle_chatbot";
export const GET_PROPERTY_BY_NAME = "/properties/";
export const PROPERTY_GET_CONVERSATION = "/properties/";
export const GET_ACTIONS_ITEMS = "/get_action_items";
export const COMPLETE_ACTIONS_ITEMS = "/complete_action_item";
export const COPY_QUESTIONNAIRE = "/properties";
export const MESSAGE_FEEDBACK = "/properties";
export const REMOVE_SUPPORTING_DOCS = "/properties";
