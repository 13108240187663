export const ErrorMessageKey = {
  THIS_FIELD_REQUIRED: "This field is required",
  PLEASE_ENTER_YOUR_NAME: "Please enter your name",
  PLEASE_ENTER_YOUR_LAST_NAME: "Please enter your last name",
  PLEASE_ENTER_YOUR_EMAIL: "Please enter your email",
  INVALID_EMAIL_ADDRESS: "Invalid email address",
  PLEASE_ENTER_YOUR_PASSWORD: "Please enter password",
  PLEASE_ENTER_YOUR_OLD_PASSWORD: "Please enter old password",
  PASSWORD_MUST_BE_AT_LEAST_8_CHARACTER_LONG: "Password must be at least 8 characters long!",
  MIX_IT_UP_USE_A_COMBINATION_OF_UPPERCASE_AND_LOWERCASE_LETTERS_SPECIAL_CHARACTERS_IN_YOUR: "Mix it up! Use a combination of uppercase and lowercase letters, special characters (!$@%) in your password",
  PASSWORD_DOESNT_MATCH: "password doesn't match",
  PLEASE_SELECT_OR_ENTER_HOW_MANY_PROPERTIES_WANT_TO_ADD: "Please select or enter how many properties want to add.",
  ONLY_FIVETY_PROPERTIES_CAN_BE_ADDED: "Max 1000 properties. To add more, contact us.",
  PLEASE_ONE_PLAN_SELECT: "Please select a plan",
  PLEASE_ENTER_YOUR_PHONE_NUMBER: "Please enter your phone number",
  PLEASE_ENTER_A_VALID_PHONE_NUMBER: "Please enter a valid phone number",
};
