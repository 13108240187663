// @flow
import { AddPropertiesActionTypes } from './constants';

export const testingApiActions = (data) => ({
    type: AddPropertiesActionTypes.TESTING_API_FIRST,
    data,
});
export const goToBillingportalPostActions = (data) => ({
    type: AddPropertiesActionTypes.GO_TO_BILLING_PORTAL_POST_FIRST,
    data,
});

export const postPropertiesActions = (data) => ({
    type: AddPropertiesActionTypes.POST_PROPERTIES_FIRST,
    data,
});

export const getQuestionnaireActions = (data) => ({
    type: AddPropertiesActionTypes.GET_QUESTIONNAIRE_FIRST,
    data,
});

export const resetQuestionnaireStateActions = () => ({
    type: AddPropertiesActionTypes.GET_QUESTIONNAIRE_RESET,
});

export const pullConversationDataActions = (data) => ({
    type: AddPropertiesActionTypes.PULL_CONVERSATION_DATA_FIRST,
    data,
});

export const listIntegrationPropertiesActions = (data) => ({
    type: AddPropertiesActionTypes.LIST_INTEGRATION_PROPERTIES_FIRST,
    data
});

export const deleteListIntegrationPropertiesActions = (data) => ({
    type: AddPropertiesActionTypes.DELETE_LIST_INTEGRATION_PROPERTIES_FIRST,
    data,
})

export const updateQuestionnaireActions = (data) => ({
    type: AddPropertiesActionTypes.UPDATE_QUESTIONNAIRE_FIRST,
    data,
});

export const supportingDocumentPostActions = (data) => ({
    type: AddPropertiesActionTypes.SUPPORTING_DOCUMENT_POST_FIRST,
    data,
});

export const supportingUrlPostActions = (data) => ({
    type: AddPropertiesActionTypes.SUPPORTING_URL_POST_FIRST,
    data,
});
export const toggleChatbotoNoFFPutActions = (data) => ({
    type: AddPropertiesActionTypes.TOGGLE_CHATBOT_ONOFF_PUT_FIRST,
    data,
});

export const defaultActions = (data) => ({
    type: AddPropertiesActionTypes.TOGGLE_CHATBOT_ONOFF_PUT_FIRST,
    data,
});

export const copyExistingPropertyActions = (data) => ({
    type: AddPropertiesActionTypes.COPY_EXITING_PROPERTY_FIRST,
    data,
});

export const removeSupportingDocsActions = (data) => ({
    type: AddPropertiesActionTypes.REMOVE_SUPPORTING_DOCS_FIRST,
    data,
});











