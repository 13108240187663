import './App.css';
import Routing from "./routes/Routes";
function App() {
  return (
      <div className="App">
        <Routing />
      </div>
  );
}

export default App;
