import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { LoginActionTypes } from "./constants";
import { loginEndPoint } from "./api";
import { StateEmtpyActionTypes } from "../../stateEmpty/constants";
import { APICore, setAuthorization } from "../../../helper/apiCore";

const api = new APICore();

function* loginFunction(action) {
  const { rememberMe, ...loginData } = action?.data || {};

  try {
    yield put({
      type: LoginActionTypes.LOGIN_LOADING,
      payload: {},
    });
    const response = yield call(loginEndPoint, loginData);
    if (response.status === 200) {
      let access_token = response?.data?.access_token;
      let refresh_token = response?.data?.refresh_token;
      const user = {
        data: "userData",
        id: 1,
        password: "test",
        lastName: "User",
        role: "userRole",
        token: access_token, // I believe this is the only prop that's actually used. The rest is just dummy. TODO: delete once confirmed
        refreshToken: refresh_token,
      };
      api.setLoggedInUser(user, rememberMe);
      setAuthorization(user["token"]);
      yield put({
        type: LoginActionTypes.LOGIN_SUCCESS,
        payload: { ...response.data, status: response.status },
      });
      yield put({
        type: LoginActionTypes.LOGIN_RESET,
        payload: {},
      });
    } else if (response.status === 202) { // credentials good, but user hasn't confirmed email yet. Backend will not provide tokens until email is confirmed. State still needs to be updated so login.jsx can redirect to confirm-email
      yield put({
        type: LoginActionTypes.LOGIN_SUCCESS, // should maybe create a new event for this? But this works for now
        payload: { ...response.data, status: response.status },
      });
    } else {
      yield put({
        type: LoginActionTypes.LOGIN_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: LoginActionTypes.LOGIN_ERROR,
      payload: error,
    });
  }
}

function* stateEmptyFunction() {
  yield put({
    type: StateEmtpyActionTypes.STATE_EMPTY_SUCCESS,
    payload: {},
  });
}

export function* acctionLogin(): any {
  yield takeEvery(LoginActionTypes.LOGIN_FIRST, loginFunction);
}

export function* acctionStateEmpty(): any {
  yield takeEvery(StateEmtpyActionTypes.STATE_EMPTY_FIRST, stateEmptyFunction);
}

function* loginSaga(): any {
  yield all([fork(acctionLogin), fork(acctionStateEmpty)]);
}

export default loginSaga;
