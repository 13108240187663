export const AddPropertiesActionTypes = {
    TESTING_API_FIRST: '@@auth/TESTING_API_FIRST',
    TESTING_API_LOADING: '@@auth/TESTING_API_LOADING',
    TESTING_API_SUCCESS: '@@auth/TESTING_API_SUCCESS',
    TESTING_API_ERROR: '@@auth/TESTING_API_ERROR',
    TESTING_API_RESET: '@@auth/TESTING_API_RESET',

    GO_TO_BILLING_PORTAL_POST_FIRST: '@@auth/GO_TO_BILLING_PORTAL_POST_FIRST',
    GO_TO_BILLING_PORTAL_POST_LOADING: '@@auth/GO_TO_BILLING_PORTAL_POST_LOADING',
    GO_TO_BILLING_PORTAL_POST_SUCCESS: '@@auth/GO_TO_BILLING_PORTAL_POST_SUCCESS',
    GO_TO_BILLING_PORTAL_POST_ERROR: '@@auth/GO_TO_BILLING_PORTAL_POST_ERROR',
    GO_TO_BILLING_PORTAL_POST_RESET: '@@auth/GO_TO_BILLING_PORTAL_POST_RESET',

    POST_PROPERTIES_FIRST: '@@auth/POST_PROPERTIES_FIRST',
    POST_PROPERTIES_LOADING: '@@auth/POST_PROPERTIES_LOADING',
    POST_PROPERTIES_SUCCESS: '@@auth/POST_PROPERTIES_SUCCESS',
    POST_PROPERTIES_ERROR: '@@auth/POST_PROPERTIES_ERROR',
    POST_PROPERTIES_RESET: '@@auth/POST_PROPERTIES_RESET',

    GET_QUESTIONNAIRE_FIRST: '@@auth/GET_QUESTIONNAIRE_FIRST',
    GET_QUESTIONNAIRE_LOADING: '@@auth/GET_QUESTIONNAIRE_LOADING',
    GET_QUESTIONNAIRE_SUCCESS: '@@auth/GET_QUESTIONNAIRE_SUCCESS',
    GET_QUESTIONNAIRE_ERROR: '@@auth/GET_QUESTIONNAIRE_ERROR',
    GET_QUESTIONNAIRE_RESET: '@@auth/GET_QUESTIONNAIRE_RESET',

    PULL_CONVERSATION_DATA_FIRST: '@@auth/PULL_CONVERSATION_DATA_FIRST',
    PULL_CONVERSATION_DATA_LOADING: '@@auth/PULL_CONVERSATION_DATA_LOADING',
    PULL_CONVERSATION_DATA_SUCCESS: '@@auth/PULL_CONVERSATION_DATA_SUCCESS',
    PULL_CONVERSATION_DATA_ERROR: '@@auth/PULL_CONVERSATION_DATA_ERROR',
    PULL_CONVERSATION_DATA_RESET: '@@auth/PULL_CONVERSATION_DATA_RESET',

    LIST_INTEGRATION_PROPERTIES_FIRST: '@@auth/LIST_INTEGRATION_PROPERTIES_FIRST',
    LIST_INTEGRATION_PROPERTIES_LOADING: '@@auth/LIST_INTEGRATION_PROPERTIES_LOADING',
    LIST_INTEGRATION_PROPERTIES_SUCCESS: '@@auth/LIST_INTEGRATION_PROPERTIES_SUCCESS',
    LIST_INTEGRATION_PROPERTIES_ERROR: '@@auth/LIST_INTEGRATION_PROPERTIES_ERROR',

    DELETE_LIST_INTEGRATION_PROPERTIES_FIRST: '@@auth/DELETE_LIST_INTEGRATION_PROPERTIES_FIRST',
    DELETE_LIST_INTEGRATION_PROPERTIES_LOADING: '@@auth/DELETE_LIST_INTEGRATION_PROPERTIES_LOADING',
    DELETE_LIST_INTEGRATION_PROPERTIES_SUCCESS: '@@auth/DELETE_LIST_INTEGRATION_PROPERTIES_SUCCESS',
    DELETE_LIST_INTEGRATION_PROPERTIES_ERROR: '@@auth/DELETE_LIST_INTEGRATION_PROPERTIES_ERROR',
    DELETE_LIST_INTEGRATION_PROPERTIES_RESET: '@@auth/DELETE_LIST_INTEGRATION_PROPERTIES_RESET',

    UPDATE_QUESTIONNAIRE_FIRST: '@@auth/UPDATE_QUESTIONNAIRE_FIRST',
    UPDATE_QUESTIONNAIRE_LOADING: '@@auth/UPDATE_QUESTIONNAIRE_LOADING',
    UPDATE_QUESTIONNAIRE_SUCCESS: '@@auth/UPDATE_QUESTIONNAIRE_SUCCESS',
    UPDATE_QUESTIONNAIRE_ERROR: '@@auth/UPDATE_QUESTIONNAIRE_ERROR',
    UPDATE_QUESTIONNAIRE_RESET: '@@auth/UPDATE_QUESTIONNAIRE_RESET',

    SUPPORTING_DOCUMENT_POST_FIRST: '@@auth/SUPPORTING_DOCUMENT_POST_FIRST',
    SUPPORTING_DOCUMENT_POST_LOADING: '@@auth/SUPPORTING_DOCUMENT_POST_LOADING',
    SUPPORTING_DOCUMENT_POST_SUCCESS: '@@auth/SUPPORTING_DOCUMENT_POST_SUCCESS',
    SUPPORTING_DOCUMENT_POST_ERROR: '@@auth/SUPPORTING_DOCUMENT_POST_ERROR',
    SUPPORTING_DOCUMENT_POST_RESET: '@@auth/SUPPORTING_DOCUMENT_POST_RESET',

    SUPPORTING_URL_POST_FIRST: '@@auth/SUPPORTING_URL_POST_FIRST',
    SUPPORTING_URL_POST_LOADING: '@@auth/SUPPORTING_URL_POST_LOADING',
    SUPPORTING_URL_POST_SUCCESS: '@@auth/SUPPORTING_URL_POST_SUCCESS',
    SUPPORTING_URL_POST_ERROR: '@@auth/SUPPORTING_URL_POST_ERROR',
    SUPPORTING_URL_POST_RESET: '@@auth/SUPPORTING_URL_POST_RESET',

    TOGGLE_CHATBOT_ONOFF_PUT_FIRST: '@@auth/TOGGLE_CHATBOT_ONOFF_PUT_FIRST',
    TOGGLE_CHATBOT_ONOFF_PUT_LOADING: '@@auth/TOGGLE_CHATBOT_ONOFF_PUT_LOADING',
    TOGGLE_CHATBOT_ONOFF_PUT_SUCCESS: '@@auth/TOGGLE_CHATBOT_ONOFF_PUT_SUCCESS',
    TOGGLE_CHATBOT_ONOFF_PUT_ERROR: '@@auth/TOGGLE_CHATBOT_ONOFF_PUT_ERROR',
    TOGGLE_CHATBOT_ONOFF_PUT_RESET: '@@auth/TOGGLE_CHATBOT_ONOFF_PUT_RESET',


    COPY_EXITING_PROPERTY_FIRST: '@@auth/COPY_EXITING_PROPERTY_FIRST',
    COPY_EXITING_PROPERTY_LOADING: '@@auth/COPY_EXITING_PROPERTY_LOADING',
    COPY_EXITING_PROPERTY_SUCCESS: '@@auth/COPY_EXITING_PROPERTY_SUCCESS',
    COPY_EXITING_PROPERTY_ERROR: '@@auth/COPY_EXITING_PROPERTY_ERROR',
    COPY_EXITING_PROPERTY_RESET: '@@auth/COPY_EXITING_PROPERTY_RESET',

    REMOVE_SUPPORTING_DOCS_FIRST: '@@auth/REMOVE_SUPPORTING_DOCS_FIRST',
    REMOVE_SUPPORTING_DOCS_LOADING: '@@auth/REMOVE_SUPPORTING_DOCS_LOADING',
    REMOVE_SUPPORTING_DOCS_SUCCESS: '@@auth/REMOVE_SUPPORTING_DOCS_SUCCESS',
    REMOVE_SUPPORTING_DOCS_ERROR: '@@auth/REMOVE_SUPPORTING_DOCS_ERROR',
    REMOVE_SUPPORTING_DOCS_RESET: '@@auth/REMOVE_SUPPORTING_DOCS_RESET',
};


