import React from "react";
import ActionsItemsTable from "./actionItemsTable/ActionsItemsTable";
import HostDaddy from "../../component/hostDaddy/hostDaddy";

const ActionItemsIndex = () => {
  return (
    <div>
      <ActionsItemsTable />
      <HostDaddy />
    </div>
  );
};

export default ActionItemsIndex;
