import React, { useEffect, useState } from "react";
import { useSelectorUseDispatch } from "../../../../../helper/Authorized";
import ToastHandle from "../../../../../helper/ToastMessage";
import Loader, { BoxLoader } from "../../../../../helper/Loader";
import { stateEmptyActions } from "../../../../../redux/actions";
import { listIntegrationPropertiesActions } from "../../../../../redux/actions";
import DochideForReservationsModel from "./DochideForReservationsModel";

import axios from "axios";
import PopupModal from "./PopupModal";

const ExternalResourcesForm = ({ property_name, handleSaveAndNext }) => {
  const { store, dispatch } = useSelectorUseDispatch();

  const [showPreviousDoc, setShowPreviousDoc] = useState(false);
  const [showDocHideForResrv, setDocHideForResrv] = useState(false);
  const [prevUploadedDoc, setPrevUploadedDoc] = useState([]);
  const [hideForReservation, setHideForReservatin] = useState([]);
  const [prevLinkedIntegration, setPrevLinkedIntegration] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState("");
  const [docUploadIsLoading, setdocUploadIsLoading] = useState(false);
  const [suppertingInput, setSuppertingInput] = useState({ updateDoc: true, urlToWebPage: false, pmsIntegration: false });

  let updateDocN = "updateDoc";
  let urlToWebPageN = "urlToWebPage";
  let pmsIntegrationN = "pmsIntegration";
  const suppertingOnclick = (type) => {
    if (type === updateDocN) { setSuppertingInput({ updateDoc: true }); }
    else if (type === urlToWebPageN) { setSuppertingInput({ urlToWebPage: true }); }
    else if (type === pmsIntegrationN) { setSuppertingInput({ pmsIntegration: true }); }
  };
  const supportingStatus = suppertingInput?.updateDoc ? store?.supportingDocumentPostReducer?.supportingDoc?.status : suppertingInput?.urlToWebPage ? store?.supportingUrlPostReducer?.supportingUrl?.status : "";
  const supportingUrlMessage = suppertingInput?.updateDoc ? store?.supportingDocumentPostReducer?.supportingDoc?.data?.error : suppertingInput?.urlToWebPage ? store?.supportingUrlPostReducer?.supportingUrl?.data?.error : "";
  const supportingLoading = suppertingInput?.updateDoc ? store?.supportingDocumentPostReducer?.loading : suppertingInput?.urlToWebPage ? store?.supportingUrlPostReducer?.loading : "";

  // list_integration_properties API Logic ------------------------------------------------------------------------------------------

  const [hasCalledAPI, setHasCalledAPI] = useState(false); // keep track of whether we've already called the list_integration_properties API. We only ever want to do it once, when the user clicks "PMS Integration"
  const [selectedIntegrationPropertyId, setSelectedIntegrationPropertyId] = useState(null); // User-selected integration property
  const [linkIsLoading, setLinkIsLoading] = useState(false);
  const [unlinkIsLoading, setUnlinkIsLoading] = useState(false);

  const integrationPropertyList = store?.listIntegrationPropertiesReducer?.listIntegrationProperties?.data?.properties; // array of integration_property objects; each with "name" and "id" properties
  const integrationPropertiesLoading = store?.listIntegrationPropertiesReducer?.loading;

  // When "PMS Integration" is selected, call API to get the list of integration properties
  useEffect(() => {
    if (!prevLinkedIntegration) {
      if (suppertingInput.pmsIntegration && !hasCalledAPI) { // If already linked to an integration, don't call the API
        dispatch(listIntegrationPropertiesActions());
        setHasCalledAPI(true);
      }
    }
  }, [suppertingInput?.pmsIntegration]);

  // -------------------------------------------------------------------------------------------------------------------------------

  function isValidURL(url) { return /^[^.].+?\..+[^.]$/.test(url); } // Check if the URL is not empty and has at least one period

  const handleUploadUrl = async () => {
    if (!isValidURL(uploadedUrl)) { return; }

    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const API_KEY = process.env.REACT_APP_API_KEY;

    const urlToSend = { url: uploadedUrl };
    
    try {
        const config = { headers: { "X-API-Key": API_KEY } };
        const response = await axios.post( `${baseUrl}/properties/${property_name}/add_url`, urlToSend, config );

        if (response.status === 200) { ToastHandle(response?.data?.message, "success"); }
        else { console.log("Error"); }
    } catch (error) {
      if (error.status === 400) {
        ToastHandle(error?.data?.error, "danger");
      } else {
        ToastHandle("Something went wrong", "danger");
      }
    }
  };

  const [file, setFile] = useState(null);
  const [getDocApiCall, setGetDocApiCall] = useState(false);

  const documentUploadMainHndle = async (resrData) => {
    if (!file) { ToastHandle("No file uploaded", "danger"); return; }

    setdocUploadIsLoading(true);
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const API_KEY = process.env.REACT_APP_API_KEY;

    let payload = new FormData();

    payload.append("file", file);
    payload.append("hide_for_reservations", JSON.stringify(resrData)); // JSON-style string representing the array of strings, per backend requirement

    try {
      const config = {
        headers: { "X-API-Key":API_KEY, "Content-Type":"multipart/form-data" },
        validateStatus: function (status) { return status >= 200 && status < 500; } // don't throw an error for non-2xx responses
      };

      const response = await axios.post( `${baseUrl}/properties/${property_name}/add_file`, payload, config );

      if (response.status === 200) {
        ToastHandle("File uploaded successfully", "success");
        setDocHideForResrv(false);
        setGetDocApiCall(true);
      } else {
        ToastHandle(response?.data?.error, "danger");
        console.log("Error", response);
      }
    } catch (error) { }
    finally { setdocUploadIsLoading(false); }
  };


  const documentUploadHandle = () => {
    if (file) { setDocHideForResrv(true); }
    else { ToastHandle("No file uploaded", "danger"); }
  };

  const handleSubmitForm = (e, uploadType) => {
    e.preventDefault();
    if (uploadType?.urlToWebPage) { handleUploadUrl(); }
    else if (uploadType?.updateDoc) { documentUploadHandle(); }
  };

  const link_integration = async (e, propertyName, integrationPropertyId) => {
    e.preventDefault();
    setLinkIsLoading(true);
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const API_KEY = process.env.REACT_APP_API_KEY;

    // Get the integrationPropertyName from the integrationPropertyId
    const selectedIntegrationProperty = integrationPropertyList.find((property) => property.id === integrationPropertyId);
    const integrationPropertyName = selectedIntegrationProperty?.name;

    try {
      const config = {
        headers: { "X-API-Key": API_KEY },
        validateStatus: function (status) { return status >= 200 && status < 500; } // don't throw an error for non-2xx responses
      };

      const jsonPayload = { platform_property_id: integrationPropertyId, platform_property_name: integrationPropertyName };
      const response = await axios.post(`${baseUrl}/properties/${propertyName}/link_to_integration`, jsonPayload, config);

      if (response.status === 200) {
        ToastHandle(response.data.message, "success");
        setPrevLinkedIntegration(integrationPropertyName);
        setSuppertingInput({ pmsIntegration: true }); // re-render "PMS Integration" section (i.e. re-click the radio button)
      } else {
        ToastHandle(response?.data?.error, "danger");
      }
    } catch (error) {
      ToastHandle("Error linking integration", "danger");
    } finally {
      setLinkIsLoading(false);
    }
  };

  const unlink_integration = async (e, propertyName) => {
    e.preventDefault();
    setUnlinkIsLoading(true);
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const API_KEY = process.env.REACT_APP_API_KEY;

    try {
      const config = { headers: { "X-API-Key": API_KEY } };
      const response = await axios.delete(`${baseUrl}/properties/${propertyName}/unlink_from_integration`, config);

      if (response.status === 200) {
        ToastHandle(response.data.message, "success");
        setPrevLinkedIntegration(null);
        setSuppertingInput({ pmsIntegration: true }); // re-render "PMS Integration" section (i.e. re-click the radio button)
      } else {
        ToastHandle(response.data.error, "danger");
      }
    } catch (error) {
      console.error("Error unlinking integration:", error);
      ToastHandle("Error unlinking integration", "danger");
    } finally {
      setUnlinkIsLoading(false);
    }
  };

  // Call the get property API to get the list of previously uploaded documents, and the name of any previously linked integration property
  const [previouslyGetApiLoading, setPreviousGetApiLoading] = useState(false);
  const previousUploadedDoc = async (propertyName) => {
    setPreviousGetApiLoading(true);
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const API_KEY = process.env.REACT_APP_API_KEY;

    try {
      const config = {
        headers: {"X-API-Key": API_KEY},
        validateStatus: function (status) { return status >= 200 && status < 500; } // don't throw an error for non-2xx responses
      };
      const response = await axios.get(`${baseUrl}/properties/${propertyName}`, config);

      if (response.status === 200) {
        setPreviousGetApiLoading(false);

        const propertyData = response.data.property;
        if (propertyData && propertyData.supporting_doc_items) {
          const fileData = propertyData.supporting_doc_items.file_data;
          const integrationData = propertyData.supporting_doc_items.integration_data;
          const allSupportingDocData = { ...fileData, ...integrationData };
          if (allSupportingDocData) {
            const uploadedDocs = Object.keys(allSupportingDocData);
            setPrevUploadedDoc(uploadedDocs);
            setHideForReservatin(allSupportingDocData);
          }
        }
        if (propertyData && propertyData?.integration?.integration_property_name          ) {
          setPrevLinkedIntegration(propertyData?.integration?.integration_property_name);
        }
      } else { setPreviousGetApiLoading(false); }
    } catch (error) { setPreviousGetApiLoading(false); }
  };

  // THIS FUNCTIONALITY USED DOCUMENT DELETE AFTER THAT THIS PREVIOUS UPLOAD DOCUMENT
  const deleteResAfterPreviousDocCall = () => {
    previousUploadedDoc(property_name);
  };

  // When the page is loaded, call the GET property API to get the name of any previously linked integration property.
  // TODO: have some functionality to prevent excessive calls, since there are other conditions on this page that also trigger this API call
  useEffect(() => {
    if (property_name) { previousUploadedDoc(property_name); }
  }, [property_name]);

  const handleShowPopUp = () => {
    setShowPreviousDoc(true);
    previousUploadedDoc(property_name)
  };

  useEffect(() => {
    if (supportingStatus === 404) {
      ToastHandle(supportingUrlMessage, "danger");
      dispatch(stateEmptyActions());
    } else if (supportingStatus === 400) {
      ToastHandle(supportingUrlMessage, "danger");
      dispatch(stateEmptyActions());
    }
  }, [supportingStatus]);

  useEffect(() => {
    if (getDocApiCall) {
      previousUploadedDoc(property_name);
      setGetDocApiCall(false);
    }
  }, [getDocApiCall]);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-12 form-design">
            <div>
              <h5 className="text-white fw-bold mb-3 fs-4">
                Integrate external resources here
              </h5>
            </div>
            <div className="row">
              <div className="col-4 mt-3">
                <div class="form-check custom_checkbox">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={suppertingInput?.updateDoc} onClick={() => {suppertingOnclick(updateDocN);}}/>
                  <label class="form-check-label" for="flexRadioDefault1">
                    Upload documents
                  </label>
                </div>
                <div class="old-docs mt-2" onClick={handleShowPopUp}>
                  <a href="javascript:void(0);">
                    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill="#146EF5" d="M11.6875 2H2.125V18H14.875V5H11.6875V2ZM2.125 0H12.75L17 4V18C17 18.5304 16.7761 19.0391 16.3776 19.4142C15.9791 19.7893 15.4386 20 14.875 20H2.125C1.56141 20 1.02091 19.7893 0.622398 19.4142C0.223883 19.0391 0 18.5304 0 18V2C0 1.46957 0.223883 0.960859 0.622398 0.585786C1.02091 0.210714 1.56141 0 2.125 0ZM4.25 9H12.75V11H4.25V9ZM4.25 13H12.75V15H4.25V13Z"></path>
                    </svg>
                    Previous Documents
                  </a>
                </div>
              </div>
              <div className="col-4 mt-3">
                <div class="form-check custom_checkbox">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onClick={() => {suppertingOnclick(pmsIntegrationN);}} checked={suppertingInput?.pmsIntegration}/>
                  <label class="form-check-label" for="flexRadioDefault3">
                    PMS Integration
                  </label>
                </div>
              </div>
              <form>
                {suppertingInput?.updateDoc && (
                  <div className="col-12 mt-4 ">
                    <label className="text-white">
                      Documents <span>(.txt, .docx, .pdf supported)</span>
                    </label>
                    <div className="d-flex">
                      <div className="col-6 me-4">
                        <input type="file" id="fileInput" className="form-control" accept=".txt,.docx,.pdf"
                        onChange={(e) => setFile(e.target.files[0])} />
                      </div>
                      <div className="col-3">
                        {!docUploadIsLoading ? (
                          <button className="btn btn-primary" onClick={(e) => handleSubmitForm(e, suppertingInput) }>
                            {"Submit File"}
                          </button>
                        ) : (
                          <>
                            <span style={{ color: "white" }}>
                              Submitting...
                            </span>
                            <BoxLoader />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {suppertingInput?.urlToWebPage && (
                  <div className="col-12 mt-4 ">
                    <label className="text-white">Enter URL</label>
                    <div className="">
                      <input className="bg-dark form-control" type="text" value={uploadedUrl} onChange={(e) => setUploadedUrl(e.target.value)} placeholder="Eg.example.com" />
                    </div>
                  </div>
                )}

                {suppertingInput?.pmsIntegration && (
                  <>
                    {prevLinkedIntegration ? ( // If already linked to an integration property: show the name of the linked integration property and option to unlink
                      <>
                        <div className="row">
                          <div className="col-6 mt-4 ">
                            <p style={{ color: "white", marginTop: "10px" }}>
                              Linked to property: {prevLinkedIntegration}
                            </p>
                          </div>
                          <div className="col-6 mt-4 ">
                            {unlinkIsLoading ? (
                              <>
                                <p style={{ color: "white", marginTop: "20px" }} >
                                  Unlinking...
                                </p>
                                <BoxLoader />
                              </>
                            ) : (
                              <button className="UnlinkPMSButton" onClick={(e) => unlink_integration( e, property_name ) } >
                                Unlink
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      // If not linked to n integration property: show a select with the list of integration properties (pulled from the backend API)
                      <>
                        {!integrationPropertiesLoading ? (
                          <>
                            <div className="col-12 mt-4 ">
                              {/* Vertical spacer */}
                            </div>
                            <div class="property_select">
                              {integrationPropertyList?.length > 0 ? (
                                <select id="integration_property_select" style={{ marginTop: "20px", width: "70%" }} class="form-select form-control" onChange={(e) => setSelectedIntegrationPropertyId( e.target.value )} >
                                  <option value="" disabled selected>Click to select property...</option>
                                  {integrationPropertyList?.map((property) => {
                                    // Each option shows the integration property name, but uses the integration property ID as the value
                                    return (
                                      <option key={property.id} value={property.id}>
                                        {property.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              ) : (
                                <div style={{ color: "white", marginTop: "20px", wordWrap: "break-word", width: "100%" }}>
                                  User account does not have a PMS integration. Connect your account to a PMS from the Properties page first, then you can this property to a property listing on the integration account here.
                                </div>
                              )}
                            </div>
                            <div className="col-4 mt-4 ">
                              {integrationPropertyList?.length > 0 &&
                                (linkIsLoading ? (
                                  <>
                                    <p style={{ color: "white", marginTop: "20px" }}> Linking... </p>
                                    <BoxLoader />
                                  </>
                                ) : (
                                  <button className="LinkPMSButton" onClick={(e) => link_integration( e, property_name, selectedIntegrationPropertyId )} >
                                    Link To This Property
                                  </button>
                                ))}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-12 mt-4 "> </div> {/* Vertical spacer */}
                            <p style={{ color: "white", marginTop: "20px" }}>
                              Loading integration properties...
                            </p>
                            <BoxLoader />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                
                <div style={{marginTop: '6rem', marginBottom: '3rem'}} className="d-flex justify-content-around">
                  {(!(linkIsLoading || unlinkIsLoading || (!prevLinkedIntegration && integrationPropertiesLoading))) && ( // hide next/prev buttons if loading something - but no need to hide if integrationPropertiesLoading when we're already linked to an integration property
                    <>
                      <button className="btn btn-primary" onClick={() => handleSaveAndNext(true)}> &lt; Save & Previous </button>
                      <button className="border_theme_btn previous" onClick={() => handleSaveAndNext()}> Save & Next &gt; </button>
                    </>
                  )}
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <DochideForReservationsModel show={showDocHideForResrv} setShow={setDocHideForResrv} documentUploadMainHndle={documentUploadMainHndle} btnLoading={docUploadIsLoading}/>
      {showPreviousDoc && (
        <PopupModal show={showPreviousDoc} setShow={setShowPreviousDoc} prevUploadedDoc={prevUploadedDoc} supportingDocsObj={hideForReservation} deleteResAfterPreviousDocCall={deleteResAfterPreviousDocCall} previouslyGetApiLoading={previouslyGetApiLoading} property_name={property_name}/>
      )}
    </>
  );
};

export default ExternalResourcesForm;
