// TypingIndicator.js
import React from 'react';
import './typingIndicator.css'; // You will add CSS styles for animation here

const TypingIndicator = () => {
  return (
    <div className="typing-indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default TypingIndicator;
