import React, { useEffect } from 'react';
import './bestPractices.css';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

const NotificationsGuide = () => {
  return (
    <div>
      <div className="account-main">
        <Helmet>
          <title>Action Items Notifications - HostBuddy AI</title>
          <link rel="canonical" href="https://www.hostbuddy.ai/action-items-notifications" />
        </Helmet>
        <div className="container">
          <div className="banner-heading">
            <h2>Action Items Notifications</h2>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="account-container blur-background-top-right">
                <div className="account_heading">
                  <h3>Learn how to set up notifications for action items generated by HostBuddy.</h3>
                </div>
                <div className="account-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="guide-steps">
                        
                        <div className="step-box">
                          <p>Every conversation is analyzed by HostBuddy to recognize issues that require your attention. These issues are added to your ‘Action Items’ page and can be sent to you or members of your team to ensure action is taken. By setting up notifications, you and your team can trust that HostBuddy will let you know when you need to be involved in a conversation with a guest.</p>
                        </div>

                        <div className="step-box section" id="how-it-works">
                          <h4>How It Works</h4>
                          <p>Navigate to the ‘Settings’ page.</p>
                          <p>Click on the ‘Contact’ Tab</p>
                          <p>Add an email, phone number or Slack account to your contacts.</p>
                          <p>Complete necessary verification.</p>
                          <p>Navigate to the “Notifications’ Tab</p>
                          <p>Select ‘Add A Notifications Recipient’</p>
                          <p>Complete the necessary fields</p>
                          <p>Click ‘Submit’</p>
                          <p>Voila!</p>
                        </div>

                        <div className="step-box section" id="important-notes">
                          <h4>Important Notes:</h4>
                          <p>Notifications are based on the time zone set in your account settings. Navigate to the ‘Account’ tab within settings to configure this correctly.</p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsGuide;