import React from "react";
import "./HostbuddyForGuesty.css"
import { Link } from "react-router-dom";

const HostbuddyForGuesty = () => {

  const guesty_partner_img = 'https://hostbuddylb.com/misc/guesty_partner_img.webp';

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <h2 style={{ color:'white', textAlign:'center', margin:'60px auto 20px auto', width:'60%' }}>HostBuddy AI: Revolutionizing Guest Communication for Guesty Users</h2>
          <div className="account-container h-auto blur-background-top-right my-5">
            <div class="account_heading">
              {/* <h3>HostBuddy AI: Revolutionizing Guest Communication for Guesty Users</h3> */}
            </div>
            <div class="account-content">
              <img src={guesty_partner_img} alt="guesty-partner" style={{ width:'700px', borderRadius:'20px', display:'block', margin:'10px auto 50px auto' }} />
              <p>HostBuddy AI represents the pinnacle of AI-powered guest communication in the short-term rental industry. Seamlessly integrated with Guesty, our cutting-edge AI technology delivers unparalleled automation and personalization, setting a new standard for guest interactions. Engineered to emulate human conversation, HostBuddy AI offers nuanced, context-aware responses that guests find indistinguishable from those of a dedicated human concierge. Our AI understands guest sentiment, adapts to individual communication styles, and maintains your brand voice consistently across all interactions. As the most comprehensive AI messaging solution on the market, HostBuddy AI combines advanced features with intuitive usability, making it the all-in-one tool for elevating your guest communication strategy.</p>
              <h2 className="text-white mb-3">Key Features</h2>
              <ul className="text-white d-flex flex-column ps-5 gap-3 mb-5 list-disc">
                <li><b>24/7 Automated Responses:</b> Instant, accurate replies to guest inquiries at any time, ensuring no message goes unanswered.</li>
                <li><b>Direct PMS Integration:</b> Seamless integrates with your PMS to access guest/reservation and property data.</li>
                <li><b>Smart Templating:</b> Context-aware message templates that adapt to each unique guest situation, delivering personalized communication at scale.</li>
                <li><b>Revenue Optimization:</b> Automated upsell suggestions and vacancy promotions to maximize your booking potential.</li>
                <li><b>Intelligent Issue Resolution:</b> Advanced problem-solving capabilities that can handle complex guest queries and flag issues requiring human intervention.</li>
                <li><b>Customizable AI Behavior:</b> Tailor the AI's tone, language, and decision-making processes to align perfectly with your brand.</li>
                <li><b>Review Management:</b> AI-powered tools to encourage positive reviews and address potential issues before they impact your ratings.</li>
                <li><b>Multilingual Support:</b> Communicate with guests in their preferred language, breaking down language barriers effortlessly.</li>
                <li><b>Analytics Dashboard:</b> Comprehensive insights into guest interactions, helping you refine your communication strategy.</li>
                <li><b>Action Items:</b> Automatic generation of action items based on guest conversations.</li>
                <li><b>Sentiment Analysis:</b> Real-time analysis of guest sentiment to preemptively address concerns and enhance satisfaction. </li>
              </ul>
              <p>Experience the future of guest communication with HostBuddy AI. Elevate your short-term rental business, increase guest satisfaction, and drive revenue growth – all while saving time and resources.</p>
              <Link to="/">Learn more about HostBuddy AI</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostbuddyForGuesty;
