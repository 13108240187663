export const MeetHostActionTypes = {
    CHAT_BOX_AI_FIRST: '@@auth/CHAT_BOX_AI_FIRST',
    CHAT_BOX_AI_LOADING: '@@auth/CHAT_BOX_AI_LOADING',
    CHAT_BOX_AI_SUCCESS: '@@auth/CHAT_BOX_AI_SUCCESS',
    CHAT_BOX_AI_ERROR: '@@auth/CHAT_BOX_AI_ERROR',
    CHAT_BOX_AI_RESET: '@@auth/CHAT_BOX_AI_RESET',

    GET_SESSION_ID_FIRST: '@@auth/GET_SESSION_ID_FIRST',
    GET_SESSION_ID_LOADING: '@@auth/GET_SESSION_ID_LOADING',
    GET_SESSION_ID_SUCCESS: '@@auth/GET_SESSION_ID_SUCCESS',
    GET_SESSION_ID_ERROR: '@@auth/GET_SESSION_ID_ERROR',
    GET_SESSION_ID_RESET: '@@auth/GET_SESSION_ID_RESET',

    MESSAGE_FEEDBACK_FIRST: '@@auth/MESSAGE_FEEDBACK_FIRST',
    MESSAGE_FEEDBACK_LOADING: '@@auth/MESSAGE_FEEDBACK_LOADING',
    MESSAGE_FEEDBACK_SUCCESS: '@@auth/MESSAGE_FEEDBACK_SUCCESS',
    MESSAGE_FEEDBACK_ERROR: '@@auth/MESSAGE_FEEDBACK_ERROR',
    MESSAGE_FEEDBACK_RESET: '@@auth/MESSAGE_FEEDBACK_RESET',
};
