export const PagesApisActionTypes = {
    GET_USER_DATA_FIRST: '@@auth/GET_USER_DATA_FIRST',
    GET_USER_DATA_LOADING: '@@auth/GET_USER_DATA_LOADING',
    GET_USER_DATA_SUCCESS: '@@auth/GET_USER_DATA_SUCCESS',
    GET_USER_DATA_ERROR: '@@auth/GET_USER_DATA_ERROR',
    GET_USER_DATA_RESET: '@@auth/GET_USER_DATA_RESET',

    POST_CREATE_CHECKOUT_SESSION_FIRST: '@@auth/POST_CREATE_CHECKOUT_SESSION_FIRST',
    POST_CREATE_CHECKOUT_SESSION_LOADING: '@@auth/POST_CREATE_CHECKOUT_SESSION_LOADING',
    POST_CREATE_CHECKOUT_SESSION_SUCCESS: '@@auth/POST_CREATE_CHECKOUT_SESSION_SUCCESS',
    POST_CREATE_CHECKOUT_SESSION_ERROR: '@@auth/POST_CREATE_CHECKOUT_SESSION_ERROR',
    POST_CREATE_CHECKOUT_SESSION_RESET: '@@auth/POST_CREATE_CHECKOUT_SESSION_RESET',

    UPDATE_ACCOUNT_INFO_FIRST: '@@auth/UPDATE_ACCOUNT_INFO_FIRST',
    UPDATE_ACCOUNT_INFO_LOADING: '@@auth/UPDATE_ACCOUNT_INFO_LOADING',
    UPDATE_ACCOUNT_INFO_SUCCESS: '@@auth/UPDATE_ACCOUNT_INFO_SUCCESS',
    UPDATE_ACCOUNT_INFO_ERROR: '@@auth/UPDATE_ACCOUNT_INFO_ERROR',
    UPDATE_ACCOUNT_INFO_RESET: '@@auth/UPDATE_ACCOUNT_INFO_RESET',

    UPDATE_ACCOUNT_UPDATE_PASSWORD_FIRST: '@@auth/UPDATE_ACCOUNT_UPDATE_PASSWORD_FIRST',
    UPDATE_ACCOUNT_UPDATE_PASSWORD_LOADING: '@@auth/UPDATE_ACCOUNT_UPDATE_PASSWORD_LOADING',
    UPDATE_ACCOUNT_UPDATE_PASSWORD_SUCCESS: '@@auth/UPDATE_ACCOUNT_UPDATE_PASSWORD_SUCCESS',
    UPDATE_ACCOUNT_UPDATE_PASSWORD_ERROR: '@@auth/UPDATE_ACCOUNT_UPDATE_PASSWORD_ERROR',
    UPDATE_ACCOUNT_UPDATE_PASSWORD_RESET: '@@auth/UPDATE_ACCOUNT_UPDATE_PASSWORD_RESET',

    PMS_INTEGRATION_GET_FIRST: '@@auth/PMS_INTEGRATION_GET_FIRST',
    PMS_INTEGRATION_GET_LOADING: '@@auth/PMS_INTEGRATION_GET_LOADING',
    PMS_INTEGRATION_GET_SUCCESS: '@@auth/PMS_INTEGRATION_GET_SUCCESS',
    PMS_INTEGRATION_GET_ERROR: '@@auth/PMS_INTEGRATION_GET_ERROR',
    PMS_INTEGRATION_GET_RESET: '@@auth/PMS_INTEGRATION_GET_RESET',

    GET_ACTIONS_ITEMS_FIRST: '@@auth/GET_ACTIONS_ITEMS_FIRST',
    GET_ACTIONS_ITEMS_LOADING: '@@auth/GET_ACTIONS_ITEMS_LOADING',
    GET_ACTIONS_ITEMS_SUCCESS: '@@auth/GET_ACTIONS_ITEMS_SUCCESS',
    GET_ACTIONS_ITEMS_ERROR: '@@auth/GET_ACTIONS_ITEMS_ERROR',
    GET_ACTIONS_ITEMS_RESET: '@@auth/GET_ACTIONS_ITEMS_RESET',

    PUT_COMPLETE_ACTION_ITEMS_FIRST: '@@auth/PUT_COMPLETE_ACTION_ITEMS_FIRST',
    PUT_COMPLETE_ACTION_ITEMS_LOADING: '@@auth/PUT_COMPLETE_ACTION_ITEMS_LOADING',
    PUT_COMPLETE_ACTION_ITEMS_SUCCESS: '@@auth/PUT_COMPLETE_ACTION_ITEMS_SUCCESS',
    PUT_COMPLETE_ACTION_ITEMS_ERROR: '@@auth/PUT_COMPLETE_ACTION_ITEMS_ERROR',
    PUT_COMPLETE_ACTION_ITEMS_RESET: '@@auth/PUT_COMPLETE_ACTION_ITEMS_RESET',

    GET_CALRY_LINK_FIRST: '@@auth/GET_CALRY_LINK_FIRST',
    GET_CALRY_LINK_LOADING: '@@auth/GET_CALRY_LINK_LOADING',
    GET_CALRY_LINK_SUCCESS: '@@auth/GET_CALRY_LINK_SUCCESS',
    GET_CALRY_LINK_ERROR: '@@auth/GET_CALRY_LINK_ERROR',
    GET_CALRY_LINK_RESET: '@@auth/GET_CALRY_LINK_RESET',

    REMOVE_INTEGRATION_LIST_GET_FIRST: '@@auth/REMOVE_INTEGRATION_LIST_GET_FIRST',
    REMOVE_INTEGRATION_LIST_GET_LOADING: '@@auth/REMOVE_INTEGRATION_LIST_GET_LOADING',
    REMOVE_INTEGRATION_LIST_GET_SUCCESS: '@@auth/REMOVE_INTEGRATION_LIST_GET_SUCCESS',
    REMOVE_INTEGRATION_LIST_GET_ERROR: '@@auth/REMOVE_INTEGRATION_LIST_GET_ERROR',
    REMOVE_INTEGRATION_LIST_GET_RESET: '@@auth/REMOVE_INTEGRATION_LIST_GET_RESET',

    REMOVE_INTEGRATION_FIRST: '@@auth/REMOVE_INTEGRATION_FIRST',
    REMOVE_INTEGRATION_LOADING: '@@auth/REMOVE_INTEGRATION_LOADING',
    REMOVE_INTEGRATION_SUCCESS: '@@auth/REMOVE_INTEGRATION_SUCCESS',
    REMOVE_INTEGRATION_ERROR: '@@auth/REMOVE_INTEGRATION_ERROR',
    REMOVE_INTEGRATION_RESET: '@@auth/REMOVE_INTEGRATION_RESET',
};
