export const   propertyInsightActionTypes = {
    GET_PROPERTY_INSIGHT_BY_NAME_FIRST: '@@auth/GET_PROPERTY_INSIGHT_BY_NAME_FIRST',
    GET_PROPERTY_INSIGHT_BY_NAME_LOADING: '@@auth/GET_PROPERTY_INSIGHT_BY_NAME_LOADING',
    GET_PROPERTY_INSIGHT_BY_NAME_SUCCESS: '@@auth/GET_PROPERTY_INSIGHT_BY_NAME_SUCCESS',
    GET_PROPERTY_INSIGHT_BY_NAME_ERROR: '@@auth/GET_PROPERTY_INSIGHT_BY_NAME_ERROR',
    GET_PROPERTY_INSIGHT_BY_NAME_RESET: '@@auth/GET_PROPERTY_INSIGHT_BY_NAME_RESET',

    PROPERTY_GET_CONVERSATION_FIRST: '@@auth/PROPERTY_GET_CONVERSATION_FIRST',
    PROPERTY_GET_CONVERSATION_LOADING: '@@auth/PROPERTY_GET_CONVERSATION_LOADING',
    PROPERTY_GET_CONVERSATION_SUCCESS: '@@auth/PROPERTY_GET_CONVERSATION_SUCCESS',
    PROPERTY_GET_CONVERSATION_ERROR: '@@auth/PROPERTY_GET_CONVERSATION_ERROR',
    PROPERTY_GET_CONVERSATION_RESET: '@@auth/PROPERTY_GET_CONVERSATION_RESET',
};
